// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.responsive-img {
  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: center;
 
}

@media only screen and (max-width: 1024px){
  .responsive-img {
    margin-left: auto;
    margin-right: auto;

    display: flex;
    justify-content: center;

  }
}

@media only screen and (max-width: 600px){
  .responsive-img {
    margin-left: auto;
    margin-right: auto;

    display: flex;
    justify-content: center;

  }
}

.chart-container {
  width: 100%; 
  height: 100%;
 
}